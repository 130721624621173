// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Example
$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: true,
  arrows: true,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-home-arrows',
  prevArrow: '<button class="arrow-prev mr-5"><span class="sprite arrow-carousel-left-ye-a"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-carousel-right-ye-a"></span></button>',
  responsive: [{
    breakpoint: 1200,
    settings: {}
  },
  {
    breakpoint: 991,
    settings: {}
  },
  {
    breakpoint: 576,
    settings: {}
  },
  ]
});

// Example animations
setTimeout(function () {
  $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick-home').on('afterChange', function (event, slick, currentSlide) {
  $('#slick-home .slick-active').addClass('animation');
});

$('#slick-home').on('beforeChange', function (event, slick, currentSlide) {
  $('#slick-home .slick-active').removeClass('animation');
});


$('#slick-best-sellers').slick({
  slidesToShow: 3,
  slidesToScroll: 3,
  dots: true,
  fade: false,
  arrows: true,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-best-sellers-arrows',
  prevArrow: '<button class="arrow-prev mr-5"><span class="sprite arrow-carousel-left-ye-b"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-carousel-right-ye-b"></span></button>',
  responsive: [{
    breakpoint: 1200,
    settings: {}
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  },
  ]
});

$('#slick-product').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  arrows: false,
  autoplay: true,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
    breakpoint: 1200,
    settings: {}
  },
  {
    breakpoint: 991,
    settings: {}
  },
  {
    breakpoint: 576,
    settings: {
      autoplay: false
    }
  },
  ]
});

$("#slick-product-nav").find( '.button ' ).on('click', function () {
  var number = $(this).attr('data-trigger');
  $('#slick-product').slick('slickGoTo', parseInt(number - 1), false);
})

