// JS dependency
var stickykit = require('sticky-kit/dist/sticky-kit.js');

let offsetHeader = 0;

function calculHeaderHeight(viewport) {
  if(viewport > 1200) {
    return 124;
  }
  else if(viewport > 991 && viewport < 1200) {
    return 96;
  }
  else if(viewport > 768 && viewport < 991) {
    return 70;
  }
  else {
    return 0;
  }
};

// Initialisation
function stickykit_init() {
  var viewport = $(window).width();
  var headerHeight = $("#header").innerHeight();
  var headerResaHeight = $("#resa-header").innerHeight();
  if (viewport > 576) {
    console.log(calculHeaderHeight(viewport));
    $("#sticky-wp").stick_in_parent({
      offset_top: calculHeaderHeight(viewport),
    });
  } else {
    $("#sticky-wp").trigger("sticky_kit:detach");
  }
}

stickykit_init();

$(window).on('resize', function() {
  stickykit_init();
});
