// Radiopicker switch
$('.btn-switch-wp .label').on('click', function() {
  $(this).prev().find('.checkbox-toggle').trigger('click');
});

$('.submitOnChange').on('change', function(){
    $(this).parents('form').submit();
});


if ($("#mode-livraison").length) {
    $("#mode-livraison input").change(function() {
        $.ajax({
            method: "PUT",
            url: $("#mode-livraison").data('url'),
            data: {
                'livraison': $(this).val(),
                '_token': $("#mode-livraison").data('csrftoken')
            },
            success: function(data) {
                $('.total-resa-lateral').html(data);
            }
        });
    });

    $("#mode-livraison input:checked").trigger('change');
}

$('#contact-object').on('change', function() {
    if( $(this).val() === '4' ) {
        $('#autre-objet').show();
    } else {
        $('#autre-objet').hide();
    }
});

$(".declinaisons-attributs").change(function() {
    var attributs = [];
    $('.declinaisons-attributs').each(function( index ) {
        if( $(this).val() !== "" ) {
            attributs.push($(this).val());
        }
    });
    $.ajax({
        method: "GET",
        url: $("#add-panier").data('url'),
        data: {
            'attributs': attributs
        },
        success: function(data) {
            $('#stock-produit').html(data.view_stock);
            $('#add-panier').html(data.view_add_panier);
            $('#add-panier').html(data.view_add_panier);

            $('.selectpicker').selectpicker({
                container: 'body'
            });
            $('.reference').html(data.reference);
        }
    });
});
